import React from "react";
import { Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import CookbookSection from "../../components/cookbookSection";

const MainMealCookbookSection = ({ location }) => {
  return (
    <CookbookSection
      location={location}
      sectionLink="main-meals"
      sectionName="Main Meals"
      sectionTitle="Main Meal"
      prevLink="/cookbook/dutch-oven-desserts/"
      nextLink="/cookbook/dutch-oven-main-meals/"
    >
      <Row className="d-flex pb-4 pt-4 flex-wrap justify-content-center" id="cookbook-top-text">
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div>Enjoy dinner after a long day of hiking or canoeing</div>
          <div className="pt-2">
            <StaticImage
              width={300}
              src="../../images/cookbook/chicken-on-grill.jpg"
              alt="Chicken and peppers on grill"
            />
          </div>
        </div>
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div className="flex-fill">... or sitting</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/tent-in-woods.jpg" alt="Tent in woods" />
          </div>
        </div>
      </Row>
    </CookbookSection>
  );
};

export default MainMealCookbookSection;
